@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900&display=swap");

body {
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

.service-name {
  @apply text-primary uppercase block font-semibold text-sm tracking-widest;
}

.title {
  @apply capitalize text-2xl sm:text-3xl font-semibold my-3;
}


.team-card:hover :is(h2, p) {
  color: #063785;
}

.team-card:hover .icons {
  transform: translateY(0);
  opacity: 1;
}

::selection {
  @apply bg-primary  text-white;
}

/* ===== ::-webkit-scrollbar ===== */
::-webkit-scrollbar {
  @apply hidden;
}

::-webkit-scrollbar-thumb {
  @apply hidden;
}

@layer components {
  /* section class */
  .section {
    @apply lg:pt-32 pt-28 lg:pb-16 pb-0;
  }
  
  .section-title {
    @apply pb-4 font-dm text-[28px] font-medium text-gray-900 dark:text-white md:text-[35px] lg:text-[38px];
  }

  .section-text {
    @apply pb-8 text-[15px] font-medium text-gray-600 dark:text-white;
  }

  /* container class */
  .container {
    @apply mx-auto w-[calc(100%-3rem)] max-w-6xl;
  }
}

.rotate {
  transform: rotateX(180deg);
}
.rotate30 {
  transform: rotateX(30deg);
}
/* Keyframe animations */
@keyframes anim-rotate {
  0% {
    transform: rotate(-30deg);
  }
  50% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(-30deg);
  }
}
@keyframes anim-updown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0);
  }
}
.elem-updown {
  animation: anim-updown 5s infinite;
}
@keyframes anim-move {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(30px);
  }
  100% {
    transform: translateX(0);
  }
}
.elem-move {
  animation: anim-move 5s infinite;
}
@keyframes anim-rotate-full {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.elem-rotate-full {
  animation: anim-rotate-full 12s infinite;
}
@keyframes anim-zoom {
  0% {
    transform: scale(0.2);
  }
  50% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(0.2);
  }
}
.elem-zoom {
  animation: anim-zoom 5s infinite;
}
@keyframes anim-fade {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.1;
  }
}
.elem-fade {
  animation: anim-fade 5s infinite;
}

.fade-in-image {
  animation: fadeIn 3s;
  transition: 3s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.animate-blink {
  animation: blink 0.7s infinite;
}
